@import '../../app/styles/utils.scss';

.root {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  background-color: color(common, white);
  z-index: 100;
  padding: 0 25px;

  &.borderBottom,
  &.mainBorderBottom {
    box-shadow: inset 0px -1px 1px #E5EAF2;
  }

  .content {
    display: flex;
    justify-content: space-between;
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;

    .left {
      display: inline-flex;
      align-items: center;
    }

    .center {
      width: 40%;
    }

    .right {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 900px) {
    padding: 0 15px;
  }

  @media (max-width: 800px) {
    &.borderBottom {
      box-shadow: none;
    }

    .content {
      .center {
        position: absolute;
        top: 60px;
        width: 100%;
        left: 0;
      }
    }
  }
}
