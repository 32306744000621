@import '../../../../app/styles/utils.scss';

.root {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;

  img {
    max-height: 25px;
  }

  .mobile {
    display: none;
  }

  @include media-query-up-to-small-laptop() {
    .mobile {
      display: flex;
    }

    .desktop {
      display: none;
    }
  }
}
