body {
  margin: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;

  @media (max-width: 800px) {
    padding-top: 105px;
  }
}

* {
  font-family: 'Montserrat', sans-serif !important;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
