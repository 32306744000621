@import '../../../app/styles/utils.scss';

.root {
  margin-bottom: 15px;
  width: 100%;

  .field {
    border-radius: 10px;
    padding: 2px 10px;
    border: 1px solid color(gray, 200);
    background: color(common, white);
    width: 100%;
    height: 42px;
    outline: none;
  }
}