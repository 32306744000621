@import '../../app/styles/utils.scss';

.root {
  margin: 0;
  padding: 0;

  &.display1 {
    font-weight: 600;
    font-size: 36px;
    margin: 10px 0;
  }

  &.display2 {
    font-weight: 600;
    font-size: 22px;
    margin: 10px 0;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  &.display3 {
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
  }

  &.display4 {
    font-size: 12px;
    color: color(gray, 600);
    line-height: 20px;
    padding: 10px 0;
  }
}