@import '../../../app/styles/utils.scss';

.root {
  margin-bottom: 15px;

  .inner {
    display: flex;
    cursor: pointer;
  
    input {
      width: 0;
      height: 0;
      position: absolute;
    }

    .styledCheckbox {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 2px solid color(gray, 200);
      margin-right: 10px;

      svg {
        display: none;
        width: 100%;
        height: 100%;
        fill: color(common, white);
      }
    }

    .content {
      width: calc(100% - 30px);
      font-size: 13px;
      color: color(gray, 600);
      line-height: 20px;
    }

    input:checked + .styledCheckbox {
      background: #1976d2;
      border-color: #1976d2;
      align-items: center;

      svg {
        display: block;
      }
    }
  }
}