.root {
  .wishlistBtnRoot {
    text-transform: none;
    color: #212121;
    position: relative;

    svg {
      font-size: 20px;
    }

    .wishlistTag {
      background: #F01469;
      font-size: 10px;
      color: #fff;
      border-radius: 10px;
      padding: 0 2px;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      position: absolute;
      top: -3px;
      right: -15px;
    }
  
    .wishlistIconActive {
      color: #F01469;
    }

    @media (max-width: 900px) {
      svg {
        font-size: 28px;
      }

      .wishlistTag {
        right: -10px;
      }
    }

    @media (max-width: 600px) {
      margin: 0;
      min-width: auto;
      padding: 0;

      & > span {
        margin: 0;
      }

      .wishlistTag {
        right: -8px;
      }

      .text {
        display: none;
      }
    }
  }
}
