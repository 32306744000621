@mixin media-query-mobile {
  @media (max-width: 480px) { @content; }
}

@mixin media-query-tablet {
  @media (min-width: 481px) and (max-width: 768px) { @content; }
}

@mixin media-query-small-laptop {
  @media (min-width: 769px) and (max-width: 1024px) { @content; }
}

@mixin media-query-laptop {
  @media (min-width: 1025px) and (max-width: 1200px) { @content; }
}

@mixin media-query-desktop {
  @media (min-width: 1201px) { @content; }
}

@mixin media-query-up-to-small-laptop {
  @media (max-width: 1024px) { @content; }
}
