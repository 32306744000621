.root {
  h2 {
    margin-bottom: 15px;
    padding: 0 10px;
  }

  .showMore {
    button {
      text-transform: none;
    }
  }

  .titleRoot {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      .navigation {
        display: flex;
        margin-left: 40px;
        width: 90px;
        justify-content: space-between;

        button {
          border: 1px solid #eee;

          svg {
            fill: #1976d2;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    h2 {
      padding: 0;
    }
  }

  @media (max-width: 800px) {
    .titleRoot {
      .left {
        .navigation {
          display: none;
        }
      }
    }
  }
}