.root {
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .checkboxes {
    .input {
      display: flex;
      align-items: center;

      & > div {
        margin-bottom: 2px;
  
        label {
          & > div {
            margin-top: 0 !important;
            font-size: 14px !important;
            color: #000 !important;
          }
  
          input:checked {
            & ~ div {
              font-weight: 600;
            }
          }
        }
      }

      .count {
        margin-bottom: 2px;
        display: inline-block;
        background: #eee;
        margin-left: 5px;
        padding: 2px 5px;
        border-radius: 4px;
        font-size: 10px;
      }
    }
  }

  .showMore {
    text-align: right;

    button {
      color: #1976d2;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
}